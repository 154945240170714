import cssVars from 'css-vars-ponyfill'
import {each, pickBy, startsWith} from 'lodash'

///////////////////////////////////////////////////////////////////////////////

export class CssVarsOverrider {
  private vars: any
  private isFetched: boolean = false

  hasNativeSupport(): boolean {
    return window['CSS'] && window['CSS'].supports && window['CSS'].supports('(--a: 0)')
  }

  fetchVars(): Promise<void> {
    if (this.isFetched) {
      return Promise.resolve()
    }

    return new Promise((resolve) => {
      cssVars({
        onlyLegacy: false,
        preserveStatic: true,
        onComplete: (cssText, styleElements, cssVariables) => {
          this.vars = pickBy(cssVariables, (v, k) => startsWith(k, '--tamaro-'))
          this.isFetched = true
          resolve()
        },
      })
    })
  }

  polyfill(): Promise<void> {
    if (this.hasNativeSupport()) {
      let root = document.documentElement

      each(this.vars, (v, k) => {
        root.style.setProperty(k, v)
      })

      return Promise.resolve()
    }

    return new Promise((resolve) => {
      cssVars({
        onlyLegacy: false,
        preserveStatic: true,
        variables: this.vars,
        onComplete: () => {
          resolve()
        },
      })
    })
  }
}

///////////////////////////////////////////////////////////////////////////////

export function createCssVarsOverrider() {
  return new CssVarsOverrider()
}
