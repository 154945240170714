import "core-js/stable"
import "regenerator-runtime/runtime"
import 'element-closest/browser.js'
import axios from 'axios'
import {toJS} from 'mobx'
import * as jsyaml from 'js-yaml'
import {isPlainObject, isString, isArray, reduce, map} from 'lodash'
import {CreateWidgetArgs} from '../tamaro/components/Widget'
import {WidgetConfig} from '../tamaro/lib/WidgetConfig'
import {createEvents, WidgetEventName, WidgetEvents} from '../tamaro/lib/WidgetEventer'
import {WidgetApi} from '../tamaro/lib/WidgetApi'
import {createCssVarsOverrider, CssVarsOverrider} from '../tamaro/lib/CssVarsOverrider'

///////////////////////////////////////////////////////////////////////////////

export interface IWidgetPreloader {
  events: WidgetEvents

  runWidget(targetSelector: string, runtimeWidgetConfig?: WidgetConfig): Promise<WidgetApi>

  loadWidget(): Promise<void>
  createWidget(runtimeWidgetConfig?: WidgetConfig): Promise<WidgetApi>
  renderWidget(api: WidgetApi, targetSelector: string): Promise<void>
  removeWidget(targetSelector: string): void

  renderSpinner(targetSelector: string): Promise<void>
  removeSpinner(targetSelector: string): void

  fetchRemoteConfig(url: string): Promise<any>
  toJS(data: any): any
}

type WidgetModule = {
  createWidget(args: CreateWidgetArgs): Promise<WidgetApi>
  renderWidget(api: WidgetApi, targetSelector: string): Promise<void>
  removeWidget(targetSelector: string): void
}

type Resolvers = {
  initialConfig(): Promise<WidgetConfig>
  preloaderCSS(): Promise<any>
  widgetCSS(): Promise<any>
}

type WidgetPreloaderArgs = {
  resolvers?: Partial<Resolvers>
}

///////////////////////////////////////////////////////////////////////////////

export const normalizeValues = (data: any): any => {
  if (isString(data)) {
    data = decodeURIComponent(data)

    if (data.toLowerCase() === 'null') {
      return null
    }

    if (data.toLowerCase() === 'true') {
      return true
    }

    if (data.toLowerCase() === 'false') {
      return false
    }

    if (data.toLowerCase() === 'undefined') {
      return undefined
    }
  }

  if (isArray(data)) {
    return map(data, v => normalizeValues(v))
  }

  if (isPlainObject(data)) {
    return reduce(data, (res, v, k) => {
      res[k] = normalizeValues(v)

      return res
    }, {})
  }

  return data
}

///////////////////////////////////////////////////////////////////////////////

export class WidgetPreloader implements IWidgetPreloader {
  public events: WidgetEvents = createEvents()
  private cssVarsOverrider: CssVarsOverrider
  private isLoaded: boolean = false
  private widgetModule: WidgetModule
  private resolvers: Resolvers = {
    initialConfig: async () => ({} as WidgetConfig),
    preloaderCSS: () => import('./styles/preloader'),
    widgetCSS: () => import('../tamaro/styles/widget'),
  }
  public toJS: (data: any) => any

  constructor(args?: WidgetPreloaderArgs) {
    this.setup(args)
    this.toJS = toJS.bind(this)
  }

  setup(args: WidgetPreloaderArgs = {}) {
    let {resolvers} = args

    if (resolvers) {
      for (let k of Object.keys(resolvers)) {
        this.resolvers[k] = resolvers[k]
      }
    }

    this.cssVarsOverrider = createCssVarsOverrider()
  }

  async runWidget(targetSelector: string, runtimeWidgetConfig: WidgetConfig = {}) {
    await this.renderSpinner(targetSelector)
    await this.loadWidget()
    let api = await this.createWidget(runtimeWidgetConfig)
    await this.renderWidget(api, targetSelector)

    return api
  }

  async createWidget(runtimeWidgetConfig: WidgetConfig = {}) {
    this.assertWidgetLoaded()

    let events = this.events
    let initialWidgetConfig = await this.resolvers.initialConfig()
    let widgetConfig = {
      ...initialWidgetConfig,
      ...runtimeWidgetConfig,
    }

    return await this.widgetModule.createWidget({widgetConfig, events})
  }

  async renderWidget(api: WidgetApi, targetSelector: string) {
    this.assertWidgetLoaded()
    await this.widgetModule.renderWidget(api, targetSelector)
  }

  removeWidget(targetSelector: string) {
    this.assertWidgetLoaded()
    this.widgetModule.removeWidget(targetSelector)
  }

  async loadWidget() {
    await this.events[WidgetEventName.BEFORE_LOAD].publish()
    await this.cssVarsOverrider.fetchVars()

    let [_, widgetModule] = await Promise.all([
      this.resolvers.widgetCSS(),
      import('../tamaro'),
    ])

    await this.cssVarsOverrider.polyfill()
    this.widgetModule = widgetModule
    this.isLoaded = true
    await this.events[WidgetEventName.AFTER_LOAD].publish()
  }

  async fetchRemoteConfig(url: string) {
    let response = null
    let data = null

    try {
      response = await axios.get(url)
    } catch(error) {
      console.error('Can\'t fetch remote config', error)
    }

    if (response && response.data) {
      if (isPlainObject(response.data)) {
        return normalizeValues(response.data)
      }

      if (isString(response.data)) {
        try {
          data = jsyaml.safeLoad(response.data)

          return normalizeValues(data)
        } catch (error) {
          console.error('Can\'t parse remote YAML config file', error)
        }
      }
    }

    return {}
  }

  async renderSpinner(targetSelector: string) {
    await this.cssVarsOverrider.fetchVars()
    await this.resolvers.preloaderCSS()
    await this.cssVarsOverrider.polyfill()

    let target = document.querySelector(targetSelector)

    // spinner won't be shown if target has children
    if (target && target.children.length === 0) {
      let preloader = document.createElement('div')
      preloader.classList.add('tamaro-preloader')

      preloader.innerHTML = `
        <svg class="icon-spinner" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 100 100" focusable="false">
          <defs>
            <clipPath id="cut-off">
              <rect x="0" y="50" width="100" height="100"/>
            </clipPath>
            <linearGradient id="gradient">
              <stop class="icon-spinner-stop" offset="0" stop-color="#000"/>
              <stop class="icon-spinner-stop" offset="100%" stop-color="#000" stop-opacity="0"/>
            </linearGradient>
          </defs>
          <circle class="icon-spinner-circle" cx="50" cy="50" r="40" fill="none" stroke-width="8"
                  stroke="url(#gradient)"
                  clip-path="url(#cut-off)"/>
        </svg>
      `

      target.appendChild(preloader)

      let textLoading = document.createElement('span')
      textLoading.setAttribute('role', 'status')
      textLoading.setAttribute('aria-live', 'polite')
      textLoading.style.position = 'absolute'
      textLoading.style.clip = 'rect(0 0 0 0)'
      preloader.appendChild(textLoading)

      setTimeout(() => {
        textLoading.innerHTML = 'Loading'
      }, 1000)
    }
  }

  removeSpinner(targetSelector: string) {
    try {
      let target = document.querySelector(targetSelector)
      let preloader = target.querySelector('.tamaro-preloader')
      preloader.parentNode.removeChild(preloader)
    } catch (err) {
    }
  }

  private assertWidgetLoaded() {
    if (!this.isLoaded) {
      throw new Error('Widget isn\'t loaded. Run loadWidget() function first.')
    }
  }
}

export function createWidgetPreloader(args?: WidgetPreloaderArgs): WidgetPreloader {
  return new WidgetPreloader(args)
}
